import React, { Suspense } from "react";
import {
  Box,
  Typography,
  Card,
  CardActionArea,
  CardContent,
  CardHeader,
} from "@mui/material";
import DrawerAppBar from "../components/navbar";

import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import EmailIcon from "@mui/icons-material/Email";
import Solutions from "../components/solutions";
import Projects from "../components/projects";

function ContactCard({ title, icon, text, link }) {
  return (
    <Card sx={{ width: 200 }}>
      <CardActionArea color="primary" sx={{ my: "auto" }} href={link}>
        <CardHeader
          title={
            <Typography variant="h5" textAlign="center">
              {title}
            </Typography>
          }
        />
        <CardContent
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            rowGap: 2,
          }}
        >
          {icon}
          <Typography variant="caption">{text}</Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

function Home(props) {
  return (
    <DrawerAppBar>
      <Box width="100%" className="bgtest" p={5} py={10} position="relative" id="accueil">
        <Typography
          textAlign="center"
          variant="h2"
          fontFamily="ThunderSemi"
          textTransform="uppercase"
          color="primary"
        >
          Limitless Logic
        </Typography>
        <Typography
          textAlign="center"
          variant="h5"
          fontFamily="ThunderSemi"
          textTransform="uppercase"
          color="primary"
        >
          Vos idées sans limites
        </Typography>
      </Box>
      <Box
        p={4}
        display="flex"
        justifyContent="center"
        flexDirection="column"
        alignItems="center"
      >
        <Suspense fallback={<Box width={20} height={20} bgcolor={"red"}></Box>}>
          <Solutions />
        </Suspense>
        <Suspense fallback={<Box width={20} height={20} bgcolor={"red"}></Box>}>
          <Projects />
        </Suspense>
        <Box width="100%" maxWidth={800} id="contacts" pt={4} mt={-4}>
          <Typography
            textAlign="left"
            variant="h4"
            fontFamily="ThunderSemi"
            textTransform="uppercase"
            mt={5}
            mb={2}
            color="primary"
          >
            Contacts
          </Typography>
          <Box
            display="flex"
            flexWrap="wrap"
            justifyContent="center"
            alignItems="center"
            gap={2}
          >
            <ContactCard
              title="Téléphone"
              icon={<LocalPhoneIcon fontSize="large" color="primary" />}
              text="07.67.18.23.48"
              link="tel:0767182348"
            />
            <ContactCard
              title="Mail"
              icon={<EmailIcon fontSize="large" color="primary" />}
              text="business@limitlesslogic.fr"
              link="mailto:business@limitlesslogic.fr?subject=[Demande de RDV]"
            />
          </Box>
        </Box>
      </Box>
    </DrawerAppBar>
  );
}

export default Home;
