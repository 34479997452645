import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Logo from "../assets/logo/logo.png";
import { Link, Typography, useTheme } from "@mui/material";
import { useScroll,motion, useSpring } from "framer-motion";

 
const drawerWidth = 240;
const navItems = [
    { nom: "Accueil", hashtag: "#accueil" },
    { nom: "Solutions", hashtag: "#solutions" },
    { nom: "Les projets", hashtag: "#lesprojets" },
    { nom: "Contacts", hashtag: "#contacts" }
]

function DrawerAppBar(props) {
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 0
    })

    const handleDrawerToggle = () => {
        setMobileOpen((prevState) => !prevState);
    };

    const {scrollYProgress} = useScroll()
    const scaleX = useSpring(scrollYProgress, {
        stiffness: 100,
        damping: 30,
        restDelta: 0.001
      });
    const theme = useTheme()


    const drawer = (
        <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
            <Box width={40} m={2} mx={'auto'}>
                <img width={'100%'} src={Logo}/>
            </Box>
            <Divider/>
            <List>
                {navItems.map((item) => (
                    <ListItem key={item.nom} disablePadding>
                        <ListItemButton href={item.hashtag} sx={{ textAlign: "center" }}>
                            <ListItemText primary={item.nom} />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
        </Box>
    );

    return (
        <Box sx={{ display: "flex" }}>
            <AppBar elevation={0} component="nav" sx={{bgcolor:"transparent",backgroundImage:'none' }}>
                <motion.div style={{width:'100%',height:'8px',scaleX:scaleX,backgroundColor:theme.palette.primary.main,position:'absolute',left:0,top:0}}></motion.div>
                <Toolbar sx={{justifyContent:'end',position:'relative', backdropFilter:'blur(2px)'}}>
                    
                 
                    <Box sx={{flexGrow:1,display:{xs:"none",sm:"block"}}}>
                        <Box width={20}>
                            <img width={'100%'} src={Logo} alt="LimitlessLogic logo"/>
                        </Box>
                    </Box>
                    <Box sx={{ display: { xs: "none", sm: "block" } }}>
                        {navItems.map((item) => (
                        <Link component={Button} key={item.nom} href={item.hashtag}  sx={{ color: "#fff", textDecoration:'none' }}>
                                {item.nom}
                            </Link>
                        ))}
                    </Box>
                    <IconButton
                        color="primary"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ mr: 0, display: { sm: "none" } }}
                    >
                        <MenuIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <nav>
                <Drawer
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: { xs: "block", sm: "none" },
                        "& .MuiDrawer-paper": {
                            boxSizing: "border-box",
                            width: drawerWidth,
                            backgroundImage:'none',
                        },
                    }}
                >
                    {drawer}
                </Drawer>
            </nav>
            <Box component="main" sx={{ width:'100%'}}>
                <Toolbar />
                {props.children}
            </Box>
        </Box>
    );
}



export default DrawerAppBar;
