import MyAnim from './logo_dark.json'
import './index.css'
import Lottie from "lottie-react";
import './THUNDER/Fonts/Web-TT/Thunder-BoldLC.css'
import './THUNDER/Fonts/Web-TT/Thunder-SemiBoldLC.css'
import "@fontsource/dm-sans"; // Defaults to weight 400
import {Box, Button, CssBaseline, Fade, ThemeProvider, Typography} from '@mui/material'
import { useEffect, useRef, useState } from 'react';
import theme from './styles/theme';
import { RouterProvider, createBrowserRouter, useNavigate } from 'react-router-dom';
import Home from './pages/Home';


function App(){
  return (
    <ProviderStack/>
  )
}

const router = createBrowserRouter([
  {
    path:'',
    element:<LandingPage/>
  },
  {
    path:'accueil',
    element:<Home/>
  }
])



function ProviderStack() {



  return (
    <ThemeProvider theme={theme}>
      <CssBaseline/>
      <RouterProvider router={router}/>
    </ThemeProvider>
  )

}

function LandingPage() {

  const [upper,setUpper] = useState(false)
  const animatedDiv = useRef(null)
  const navigate = useNavigate()

  useEffect(()=>{
    const handleAnimationEnd = (e) => {
      if(e.target ===animatedDiv.current){
        navigate('accueil')
      }else{

      }
    }

    const mydiv = animatedDiv?.current;
    if(mydiv){
      mydiv.addEventListener('transitionend',handleAnimationEnd)
    }

    return () => {
      mydiv.removeEventListener('transitionend',handleAnimationEnd)
    }

  },[])

  return (
      <Box ref={animatedDiv} className={upper ? 'bg animate' : 'bg'} justifyContent={'center'} alignItems={'center'}>
        <Box maxWidth={'100%'} px={2}>
            <Lottie loop={false} animationData={MyAnim} />
        </Box>
        <Fade in={true} style={{transitionDelay:'2.8s',transitionDuration:'1s'}}>
          <Box display={'flex'} flexDirection={'column'} justifyContent={'center'} rowGap={3} sx={{mt:3}}>
            <Typography textAlign={'center'} fontFamily={'ThunderSemi'} textTransform={'uppercase'} variant='h4'>Solutions logiciel et digitales</Typography>
            <Button sx={{letterSpacing:3,color:"whitesmoke",bgcolor:'#B75384','&:hover, &.Mui-focusVisible':{bgcolor:'#d95f9b'}}} onClick={()=>{setUpper(true)}} variant='contained'  size='large' >Demandez votre solution</Button>
          </Box>
        </Fade>
      </Box>
   );
}

export default App;
 