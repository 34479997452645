import {Box,Divider,Typography, useTheme} from '@mui/material'
import Lottie from 'lottie-react'
import MyAnim from "../assets/animations/tech_anim.json";
import MyAnimBis from "../assets/animations/tech_bis.json";
import MyAnimDash from "../assets/animations/dashboard_anim.json";
import MyAnimDashBis from "../assets/animations/dashboard_bis_anim.json";
import MultiPlatformAnim from "../assets/animations/multiplatform_anim.json";
import { Fragment } from 'react';

export default function Solutions(props){
    const theme = useTheme();

    return (
        <Fragment>
            <Box width={"100%"} maxWidth={800} id="solutions" pt={4} mt={-4} pb={2}>
            <Typography
                textAlign={"left"}
                variant="h4"
                fontFamily={"ThunderSemi"}
                textTransform={"uppercase"}
                mt={5}
                color={theme.palette.primary.main}
            >
                Les solutions
            </Typography>
            </Box>
            <Box className="grid" gap={2}>
            <Box
                className="item"
                p={2}
                display={"flex"}
                flexDirection={"column"}
                justifyContent={"center"}
            >
                <Box width={"100%"} maxWidth={500} mx={"auto"} my={3}>
                    <Lottie loop={true} animationData={MyAnimDash} />
                </Box>
                <Divider />
                <Box pt={2}>
                <Typography
                    textAlign={"center"}
                    fontFamily={"ThunderSemi"}
                    textTransform={"uppercase"}
                    variant="h5"
                >
                    Simplicité
                </Typography>
                <Typography textAlign={"center"} color={"text.secondary"}>
                    La simplicité au coeur de vos projets
                </Typography>
                </Box>
            </Box>
            <Box
                className="item"
                p={2}
                display={"flex"}
                alignItems={"center"}
                flexDirection={"column"}
                justifyContent={"end"}
            >
                <Box width={250} mx={"auto"}>
                <Lottie loop={true} animationData={MyAnimBis} />
                </Box>
                <Divider sx={{ width: "100%" }} />

                <Box pt={2}>
                <Typography
                    textAlign={"center"}
                    fontFamily={"ThunderSemi"}
                    textTransform={"uppercase"}
                    variant="h5"
                >
                    interconnectivité
                </Typography>
                <Typography textAlign={"center"} color={"text.secondary"}>
                    Décuplez votre efficacité via l'interconnexion de vos produits
                </Typography>
                </Box>
            </Box>
            <Box
                className="item"
                p={2}
                display={"flex"}
                flexDirection={"column"}
                justifyContent={"end"}
            >
                <Box width={300} mx={"auto"}>
                <Lottie loop={true} animationData={MyAnim} />
                </Box>
                <Divider />
                <Box pt={2}>
                <Typography
                    textAlign={"center"}
                    fontFamily={"ThunderSemi"}
                    textTransform={"uppercase"}
                    variant="h5"
                >
                    Systèmes embarqués
                </Typography>
                <Typography textAlign={"center"} color={"text.secondary"}>
                    Solutions embarquées pour la domotique et bien plus encore{" "}
                </Typography>
                </Box>
            </Box>
            <Box
                className="item"
                p={2}
                display={"flex"}
                flexDirection={"column"}
                justifyContent={"end"}
            >
                <Box mx={"auto"}>
                <Lottie loop={true} animationData={MultiPlatformAnim} />
                </Box>
                <Divider />
                <Box pt={2}>
                <Typography
                    textAlign={"center"}
                    fontFamily={"ThunderSemi"}
                    textTransform={"uppercase"}
                    variant="h5"
                >
                    Multi-plateforme
                </Typography>
                <Typography textAlign={"center"} color={"text.secondary"}>
                    Toutes nos solutions sont adaptative multi-plateforme
                </Typography>
                </Box>
            </Box>
            <Box
                className="item"
                p={2}
                display={"flex"}
                flexDirection={"column"}
                justifyContent={"end"}
            >
                <Box mx={"auto"}>
                <Lottie loop={true} animationData={MyAnimDashBis} />
                </Box>
                <Divider />
                <Box pt={2}>
                <Typography
                    textAlign={"center"}
                    fontFamily={"ThunderSemi"}
                    textTransform={"uppercase"}
                    variant="h5"
                >
                    Interface propriétaire
                </Typography>
                <Typography textAlign={"center"} color={"text.secondary"}>
                    Chaque solution dispose d'une interface de personnalisation et
                    de suivie de données adaptée à vos besoins
                </Typography>
                </Box>
            </Box>
            </Box>
        </Fragment>

    )
}