import {Box,Card,CardActionArea,CardContent,CardMedia,Typography, useTheme} from '@mui/material'


export default function Projects(props){
    const theme = useTheme();


    return(
        <Box width={"100%"} maxWidth={800} id="lesprojets" pt={4} mt={-4}>
        <Typography
          textAlign={"left"}
          mb={2}
          variant="h4"
          fontFamily={"ThunderSemi"}
          textTransform={"uppercase"}
          mt={5}
          color={theme.palette.primary.main}
        >
          Les projets
        </Typography>
        <Box display={"flex"} flexWrap={"wrap"} gap={2}>
          <Card sx={{ maxWidth: 345 }}>
            <CardActionArea href="https://www.d-dobel.fr/">
              <CardMedia
                component="img"
                height="140"
                image="/static/images/cards/dobel.png"
                alt="green iguana"
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  D-DOBEL
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Site vitrine pour une société de couvreur, espace de
                  création de nouveaux chantier avec images, formulaire de
                  contact personnalisé...
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
          <Card sx={{ maxWidth: 345 }}>
            <CardActionArea>
              <CardMedia
                component="img"
                height="140"
                image="/static/images/cards/freshtech.png"
                alt="green iguana"
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  Rosea ( en cours )
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Logiciel de caisse & site internet interconnectés permettant
                  l'achat en ligne et le retrait en magasin. Campagne
                  marketing , ciblage clientèle, suivi de panier moyen...
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
          <Card sx={{ maxWidth: 345 }}>
            <CardActionArea href="https://www.figma.com/file/pQR1lAuIbpe4BkIwd5AvjU/Du-lac-%C3%A0-la-bri%C3%A8re?type=design&node-id=0%3A1&mode=design&t=l0IpjoOsQWuu9TjW-1">
              <CardMedia
                component="img"
                height="140"
                image="/static/images/cards/lac.png"
                alt="green iguana"
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  Du Lac La Briere
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Example de webdesign pour un restaurateur/traiteur avec
                  système de commmande en ligne. Style rétro / authentique
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Box>
      </Box>
    )
}