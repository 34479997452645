import { createTheme } from "@mui/material";

const theme = createTheme({
    
    palette:{
        mode:'dark',
        primary:{
            main:'#D5FF3F'
        },
        secondary:{
            main:'#8e9476'
        },
        background:{
            default:"#0D1117",
            paper:"#0D1117"
        }
    },
    typography:{
        fontFamily:'DM Sans',
        
    }
})

export default theme